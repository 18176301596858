import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Connections from './pages/Connections';
import SelectTeams from './pages/SelectTeams';
import Login from './pages/Login';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          {/* Login Route as the Default Page */}
          <Route path="/login" component={Login} exact={true} />

          {/* Protected Routes */}
          <Route path="/connections" component={Connections} exact={true} />
          <Route path="/select_teams" component={SelectTeams} exact={true} />

          {/* Redirect root path to /login */}
          <Redirect from="/" to="/login" exact={true} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
